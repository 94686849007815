import {
  EHeroSectionContentWidth,
  EHeroSectionTitlePosition,
  EHeroSectionTitleTheme,
  HeroSectionTitle,
  isRichTextContentEmpty,
  RichText,
  toImageProps,
  Container,
  EContainerVariant,
  EHeroSectionPadding,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import { useRouter } from 'next/router';
import React from 'react';

import { useAppDispatch } from '../../../store/store.hooks';
import prepareCTA from '../../../utils/prepareCTA';

export type THeroSectionTitleRowConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'hero-section-title-row' }
>;

const HeroSectionTitleRowConnected: React.FC<
  THeroSectionTitleRowConnectedProps
> = props => {
  const {
    topImage,
    eyebrowsTop,
    title,
    eyebrowsBottom,
    description,
    cta,
    alignContent = EHeroSectionTitlePosition.Center,
    contentWidth,
    theme,
    padding,
  } = props;

  const appDispatch = useAppDispatch();
  const router = useRouter();
  const currentLocale = String(router.query.locale || '');

  const eyebrowsAbove = (eyebrowsTop || []).map(eyebrow => eyebrow.text);
  const richTitle =
    title && !isRichTextContentEmpty(title) ? <RichText content={title} /> : '';
  const eyebrowsBelow = (eyebrowsBottom || []).map(eyebrow => eyebrow.text);
  const richDescription =
    description && !isRichTextContentEmpty(description) ? (
      <RichText content={description} />
    ) : undefined;

  const preparedActions = prepareCTA(cta, currentLocale, appDispatch);

  return (
    <Container variant={EContainerVariant.Full}>
      <HeroSectionTitle
        eyebrowsAbove={eyebrowsAbove}
        title={richTitle}
        eyebrowsBelow={eyebrowsBelow}
        description={richDescription}
        actions={preparedActions}
        topImage={toImageProps(topImage)}
        position={alignContent as EHeroSectionTitlePosition}
        contentWidth={contentWidth as EHeroSectionContentWidth}
        theme={theme as EHeroSectionTitleTheme}
        padding={padding as EHeroSectionPadding}
      />
    </Container>
  );
};

export default HeroSectionTitleRowConnected;
